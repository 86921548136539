<template>
  <!-- корзина -->
  <div v-if="loading">
    <!-- placeholder -->
    <div v-for="n in items.length == 0 ? 1 : items.length" :key="n" class="mt-1 mb-4" aria-hidden="true">
      <div class="row py-2 row_detail placeholder-glow">
        <div data-descr="галочка" class="col col-2 order-1 col-sm-1 order-sm-1">
          <span class="placeholder placeholder-lg col-4"></span>
        </div>
        <div data-descr="описание" class="col col-7 order-5 col-sm-7 order-sm-3 col-md-4 col-xl-5 mb-2">
          <span class="placeholder col-12 bg-info"></span>
          <!-- туть -->
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
        </div>
        <div data-descr="фотки" class="col col-4 order-4 col-sm-3 offset-sm-0 order-sm-2 col-md-3 col-lg-3 col-xl-2">
          <span class="placeholder col-12"><br /><br /><br /><br /></span>
        </div>
        <div data-descr="цена" class="col col-8 order-2 col-sm-10 order-sm-4 col-md-3 col-lg-3 text-right prices">
          <span class="placeholder col-12"></span>
        </div>
        <div data-descr="кнопка" class="col col-2 order-3 col-sm-2 order-sm-5 col-md-1">
          <!-- туть -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="warning.length" class="alert alert-warning" role="alert" v-html="warning.join('<br />')"></div>
    <div class="mt-1 mb-4" v-if="items.length">
      <template v-for="item in items">
        <cart-list-detail
          v-if="item.template == 'detail'"
          :key="item.content_id"
          :item="item"
          :is-selected="selected.indexOf(item.content_id) != -1"
          :is-changing="item.changing"></cart-list-detail>
        <cart-list-other
          v-else-if="item.template == 'other'"
          :key="item.content_id"
          :item="item"
          :is-selected="selected.indexOf(item.content_id) != -1"
          :is-changing="item.changing"></cart-list-other>
        <template v-else>Нет товара в корзине.</template>
      </template>
    </div>
    <div v-else>
      <p>В корзине ничего нет, воспользуйтесь нашим каталогом, чтобы добавить товары в корзину.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import CartListDetail from './cart-list-detail.vue';
import CartListOther from './cart-list-other.vue';

export default {
  name: 'CartList',
  props: {
    cart: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    order: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data: function () {
    return {
      loading: true,
      //
      // тут полученные данные по корзине
      items: [],
      warning: [],
      //
      availables: [],
    };
  },
  components: {
    CartListDetail,
    CartListOther,
  },
  computed: {
    selected: {
      get: function () {
        return Object.keys(this.order);
      },
      // eslint-disable-next-line
      set: function () {
        // ничего не надо
      },
    },
  },
  watch: {
    cart: {
      handler: function (newVal) {
        this.items.forEach((el, index) => {
          if (Object.prototype.hasOwnProperty.call(newVal, parseInt(el.content_id))) {
            this.items[index].changing = newVal[parseInt(el.content_id)].changing;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    getCart: function (vals) {
      return new Promise((resolve, reject) => {
        if (vals === undefined) {
          resolve('Нет данных о корзине.');
          return;
        }

        if (Object.keys(vals).length == 0) {
          this.items = [];
          resolve('В корзине ничего нет.');
          return;
        }

        axios
          .post(`${this.$store.getters.urlcart}/get`, { cart: vals })
          .then((res) => {
            if (res.data.status) {
              this.items = res.data.result;
              this.warning = res.data.warning;

              Object.keys(this.items).forEach((index) => {
                this.items[index].changing = false;

                if (this.items[index].available) {
                  this.availables.push(this.items[index].content_id);
                }
              });

              // убрать элементы из заказа если их нет в корзине или они не available
              for (let key in this.order) {
                if (Object.keys(this.cart).includes(key) === false || this.availables.includes(key) === false) {
                  this.$root.$emit('remove-order-item', { id: key, type: 'id' });
                }
              }

              //this.$bs5utils.Snack.show('info', 'Корзина загружена и показана.', this.$store.getters.delay.info)
              resolve('Корзина загружена и показана.');
              return;
            } else {
              this.items = [];
              this.availables = [];
              this.warning = [];

              // почистить весь заказ
              this.$root.$emit('remove-order-item', { type: 'all' });

              this.$bs5utils.Snack.show('info', 'В корзине ничего нет.', this.$store.getters.delay.info);
              resolve('Ошибка показа корзины, в корзине ничего нет.');
              return;
            }
          })
          .catch((err) => {
            this.items = [];
            this.warning = [];

            console.error(err);

            this.$bs5utils.Snack.show('danger', err, this.$store.getters.delay.error);
            reject(new Error(err));
            return;
          });
      });
    },
  },
  created() {
    this.$root.$on('reload-cart-list', () => {
      if (this.items.length == 0) {
        this.loading = true;
      }

      this.getCart(this.cart).finally(() => {
        this.loading = false;
      });
    });
  },
};
</script>
