<template>
  <div class="row py-2 row_detail">
    <!-- фотки -->
    <div
      data-descr="фотки"
      class="col col-4 order-4 col-sm-3 offset-sm-0 order-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex align-items-center justify-content-center">
      <template v-if="item.images.arr.length">
        <template v-for="(img, index) in item.images.arr">
          <a
            v-if="index == 0"
            :key="index"
            class="fancybox-thumb w-100 d-inline-block img-hover position-relative"
            :data-fancybox="'fancybox-thumb[' + img.code + ']'"
            :data-thumb="img.thumb"
            :title="'#' + (index + 1) + ', ' + img.title"
            :data-caption="'#' + (index + 1) + ', ' + img.title"
            :href="img.link">
            <div class="images">
              <template v-for="(el, i) in item.images.arr">
                <img v-if="i < 4" :key="i" loading="lazy" class="img-fluid w-100" :src="el.thumb" :alt="el.title" />
              </template>
            </div>
            <span v-for="(el, i) in item.icons" :key="i" :class="i"><img :src="el" /></span>
          </a>
          <a
            v-else
            :key="index"
            class="fancybox-thumb d-none"
            :data-fancybox="'fancybox-thumb[' + img.code + ']'"
            :data-thumb="img.thumb"
            :title="'#' + (index + 1) + ', ' + img.title"
            :data-caption="'#' + (index + 1) + ', ' + img.title"
            :href="img.link"></a>
        </template>
      </template>
      <img
        v-else
        loading="lazy"
        class="img-fluid w-100"
        :src="item.images.noimage.thumb"
        :alt="item.images.noimage.title" />
    </div>

    <!-- описание --->
    <div
      data-descr="описание"
      class="col col-8 order-5 col-sm-7 order-sm-3 col-md-5 col-lg-4 col-xl-5 d-flex flex-column justify-content-center">
      <template v-if="item.status.show"
        ><nobr :class="item.status.color">{{ item.status.str }}</nobr
        ><br
      /></template>
      <a :href="item.url">{{ item.title }}</a>
      <div class="row descr">
        <div class="col-3">Артикул</div>
        <div class="col-9">{{ item.sku }}</div>
        <template v-if="item.oem.length"
          ><div class="col-3">OEM</div>
          <div class="col-9">{{ item.oem.join(', ') }}</div></template
        >
        <div class="col-12">доступно {{ item.instock }} шт.</div>
        <div class="col-12 pt-2 text-muted">добавлено в корзину {{ item.added }}</div>
      </div>
    </div>

    <!-- цена -->
    <div
      data-descr="цена"
      class="col col-8 order-2 col-sm-5 offset-sm-4 order-sm-4 col-md-3 offset-md-0 col-lg-4 col-xl-3 d-flex flex-column justify-content-center prices">
      <nobr>
        <span class="amount">{{ item.amount }}</span
        ><small class="text-muted"> шт.</small>
        <template v-if="item.price">
          x <span class="price">{{ item.price }}</span> ₽</template
        >
        <span v-else class="no" data-reason="no_price">цена не указана</span>
        <template v-if="item.total != 0">
          = <span class="item_total">{{ item.total }}</span> ₽</template
        >
      </nobr>
    </div>

    <!-- кнопка -->
    <div
      data-descr="кнопка"
      class="col col-2 order-3 col-sm-2 order-sm-5 col-md-1 d-flex align-items-center justify-content-center">
      <button
        style="font-size: 125%"
        v-on:click="$root.$emit('order-item-remove', item.content_id)"
        type="button"
        class="btn btn-link"
        title="Удалить из заказа">
        <font-awesome-icon icon="far fa-trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons, add icons to the library */
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
library.add(faTrashAlt);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  name: 'OrderListOther',
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data: function () {
    return {
      //
    };
  },
};
</script>
